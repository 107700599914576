import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** ¿Qué es? */}
            <div className="container lg:px-0 py-20">
                <Title>Guia Guàrdia Urbana de Barcelona 2024</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        <strong className="uppercase text-red-700">
                            * Els enviaments es realitzaran a partir de la setmana del 2 de febrer.
                        </strong>
                        <br />
                        <br />
                        Vols tenir en un sol llibre tots els temes exigits a les bases de la
                        convocatòria de Guàrdia Urbana de Barcelona 2024?
                        <br />
                        <br />
                        Et presentem el nou llibre de temari!
                        <br />
                        <br />
                        Un llibre definitiu per preparar l'oposició de Guàrdia Urbana de Barcelona
                        2024.
                        <br />
                        <br />
                        Amb esquemes, resums i èmfasi als punts forts i susceptibles de sortir a
                        l'examen.
                        <br />
                        <br />
                        <strong>TEMES QUE TROBARÀS AL LLIBRE:</strong>
                        <br />
                        <br />
                        1. La Constitució espanyola de 1978.
                        <br />
                        <br />
                        2. Llei Orgànica 6/2006, de 19 de juliol, de Reforma de l'Estatut
                        d'Autonomia de Catalunya.
                        <br />
                        <br />
                        3. Llei 7/1985, de 2 d'abril, reguladora de les Bases del Règim Local.
                        <br />
                        <br />
                        4. Llei 22/1998, de 30 de desembre, Carta Municipal de Barcelona.
                        <br />
                        <br />
                        5. Coneixement de la ciutat de Barcelona.
                        <br />
                        <br />
                        6. Institucions de la Unió Europea.
                        <br />
                        <br />
                        7. Llei 39/2015, d'1 d'octubre, de Procediment Administratiu Comú de les
                        Administracions Públiques.
                        <br />
                        <br />
                        8. El marc legal de la seguretat pública i els cossos de seguretat: 2/1986,
                        16/1991, 4/2003, 4/2015.
                        <br />
                        <br />
                        9. Actuacions relacionades amb la seguretat ciutadana . Llei d'Enjudiciament
                        Criminal. Llei Orgànica 6/1984, de 24 de maig, reguladora del procediment
                        d'habeas corpus.
                        <br />
                        <br />
                        10. L'Ordenança de mesures per fomentar i garantir la convivència ciutadana
                        a l'espai públic de Barcelona.
                        <br />
                        <br />
                        11. L'Ordenança sobre l'ús de les vies i l'espai públic de Barcelona
                        <br />
                        <br />
                        12. L'Ordenança de circulació de vianants i de vehicles
                        <br />
                        <br />
                        13. L'Ordenança de protecció, tinença i venta d'animal
                        <br />
                        <br />
                        14. Reial Decret Legislatiu 5/2015, de 30 de octubre, pel que s'aprova el
                        text refós de la Llei de l'Estatut bàsic de l'empleat públic.
                        <br />
                        <br />
                        15. Llei Orgànica 10/1995, de 23 de novembre, del Codi Penal.
                        <br />
                        <br />
                        16. Ètica i deontologia professional.
                        <br />
                        <br />
                        17. Pla d'igualtat d'oportunitats entre dones i homes de l'Ajuntament de
                        Barcelona vigent i Llei 19/2020, del 30 de desembre, d'igualtat de tracte i
                        no-discriminació.
                        <br />
                        <br />
                        No esperis més i adquireix el llibre més complet!
                    </Text>
                </div>
            </div>

            <div className="container lg:px-0 py-20">
                <Title>Preview del llibre</Title>
                <Text className="text-justify">
                    A continuació us adjuntem un resum en format pdf del contingut del curs.
                </Text>

                <p className="mt-5">
                    <a
                        rel="noopener noreferrer"
                        href={require("@static/documents/products/246/preview.pdf")}
                        target="_blank"
                        className="default-link"
                    >
                        Clic per descarregar el pdf
                    </a>
                    .
                </p>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/246/preview.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    />
                </div>
            </div>

            {/** Cómo adquirirlo */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>. Recuerda
                        que puedes combinar diferentes productos en una misma compra.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
